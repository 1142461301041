<template>
    <div v-if="stateClients.loading.clients || state.loading.summary"
        class="w-full flex justify-content-center align-items-center" style="height: 273px">
        <ProgressSpinner />
    </div>

    <div v-else>
        <div class="grid surface-card border-round p-3 m-1">

            <div class="lg:col-2 md:col-4 col-6 flex flex-column">
                <label>Clientes</label>
                <Dropdown showClear v-model="state.filter.client" optionLabel="name" :options="stateClients.clients" />
            </div>

            <div class="lg:col-2 md:col-4 col-6 flex flex-column">
                <label>Selecionar Intervalo:</label>

                <Calendar showButtonBar v-model="stateCalendarFilter.datesInterval" :show-icon="true" ref="datePicker"
                    dateFormat="dd/mm/yy" selectionMode="range" class="ml-1">
                    <template #dropdownicon>
                        <i v-tooltip.bottom="'Selecionar Intervalo'" class="pi pi-calendar p-2"></i>
                    </template>
                </Calendar>

            </div>

            <div class="lg:col-2 md:col-4 col-6 flex flex-column">
                <label>Filtrar por Períodos:</label>
                <SplitButton v-tooltip.bottom="'Buscar Hoje'" label="Hoje" @click="filterToday"
                    :model="stateCalendarFilter.calendarFilterOptions" />
            </div>

            <div class="lg:col-2 md:col-4 col-6 flex flex-column">
                <label>Status</label>
                <Dropdown showClear v-model="state.filter.status" optionLabel="name" :options="statusOptions" />
            </div>

            <div class="lg:col-2 md:col-4 col-6 flex flex-column">
                <label>Fornecedor</label>
                <InputText type="text" v-model="state.filter.vendorName" />
            </div>

            <div class="lg:col-2 md:col-4 col-6 flex flex-column mt-3">
                <label>Filtrando por Data: {{ stateCalendarFilter.periodLabelFilter }}</label>
                <label>Filtrando por Status: {{ state.statusLabelFilter.name }}</label>
            </div>

            <div class="lg:col-2 md:col-4 col-6 flex flex-column">
                <label>Pesquisar</label>
                <Button class="w-full" type="button" icon="pi pi-search" @click="loadSummary" />
            </div>

        </div>

        <div class="grid col-14 mt-2">

            <div class="col-3">

                <SummaryItem :title="'Depósitos'" :icon="'pi-arrow-down'" :textColor="'text-green-400'"
                    :amount="state.summary.depositsAmount" :count="state.summary.depositsCount"
                    :totalTax="state.summary.depositsTax"
                    :calculatedAmount="state.summary.depositsAmount - state.summary.depositsTax" />

            </div>

            <div class="col-3">

                <SummaryItem :title="'Saques'" :icon="'pi-arrow-up'" :textColor="'text-red-500'"
                    :amount="state.summary.withdrawAmount" :count="state.summary.withdrawCount"
                    :totalTax="state.summary.withdrawTax"
                    :calculatedAmount="state.summary.withdrawAmount + state.summary.withdrawTax" />


            </div>

            <div class="col-3">

                <SummaryItem :title="'Retiradas'" :icon="'pi-arrow-up'" :textColor="'text-purple-400'"
                    :amount="state.summary.removalAmount" :count="state.summary.removalCount"
                    :totalTax="state.summary.removalTax"
                    :calculatedAmount="state.summary.removalAmount + state.summary.removalTax" />

            </div>

            <div class="col-3">

                <SummaryItem :title="'Total'" :icon="'pi-chart-line'" :textColor="'text-cyan-200'"
                    :amount="state.summary.totalAmount" :count="state.summary.totalCount"
                    :totalTax="state.summary.withdrawTax + state.summary.depositsTax + state.summary.removalTax" />

            </div>


        </div>
    </div>
</template>

<script>

import { reactive } from 'vue'
import { useStore } from 'vuex'
import services from '../../services'
import ProgressSpinner from 'primevue/progressspinner'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import SplitButton from 'primevue/splitbutton'
import useCalendarFilter from '../../composables/useCalendarFilter'
import useClients from '../../composables/useClients'
import useEnumLabels from '../../composables/useEnumLabels'
import SummaryItem from '../../components/SummaryItem.vue'
import InputText from 'primevue/inputtext'

export default {
    components: {
        Button,
        Dropdown,
        Calendar,
        ProgressSpinner,
        SplitButton,
        SummaryItem,
        InputText
    },
    setup() {

        const state = reactive({
            filter: {
                client: null,
                status: null,
                vendorName: '',
            },
            loading: {
                summary: false,
            },
            summary: {
                depositsAmount: null,
                depositsCount: null,
                totalAmount: null,
                totalCount: null,
                withdrawAmount: null,
                withdrawCount: null,
            },
            selectedClient: null,
            statusLabelFilter: null
        })

        const { statusOptions } = useEnumLabels()

        const { stateClients, loadClientsWithoutPagination } = useClients()
        const { stateCalendarFilter, filterToday } = useCalendarFilter(loadSummary)
        const store = useStore()

        state.filter.status = statusOptions[1]
        filterToday()

        function loadSummary(label) {

            state.loading.summary = true
            const token = store.getters.getToken


            const params = {
                filter: {
                    ...state.filter,
                    ...stateCalendarFilter
                }
            }

            services.transactions.getSummary({ params, token })
                .then((response) => {

                    state.summary = response.data

                }).catch(() => {

                    state.loading.summary = false

                }).finally(() => {

                    state.loading.summary = false
                    changePeriodLabelFilter(label)
                    changeStatusLabelFilter()

                })
        }

        function changePeriodLabelFilter(label) {

            const labelType = typeof label

            if (stateCalendarFilter.datesInterval) {

                if (stateCalendarFilter.datesInterval[0] && !stateCalendarFilter.datesInterval[1]) {

                    stateCalendarFilter.periodLabelFilter = `${stateCalendarFilter.datesInterval[0].toLocaleDateString('pt-BR')}`

                } else if (stateCalendarFilter.datesInterval && stateCalendarFilter.datesInterval[1]) {

                    stateCalendarFilter.periodLabelFilter = `${stateCalendarFilter.datesInterval[0].toLocaleDateString('pt-BR')} - ${stateCalendarFilter.datesInterval[1].toLocaleDateString('pt-BR')}`

                }

            } else if (label && labelType == 'string') {

                stateCalendarFilter.periodLabelFilter = label

            } else {

                stateCalendarFilter.periodLabelFilter = 'Todos'

            }

        }

        function changeStatusLabelFilter() {
            state.statusLabelFilter = state.filter.status ? state.filter.status : 'Todos'
        }

        loadClientsWithoutPagination()

        function formatAmountToCurrency(amount) {
            return amount ? 'R$ ' + Number(amount.toFixed(2)) : 0
        }

        return {
            state,
            statusOptions,
            stateClients,
            stateCalendarFilter,
            filterToday,
            changePeriodLabelFilter,
            changeStatusLabelFilter,
            loadSummary,
            formatAmountToCurrency,
        }

    }
}
</script>