<template>
    <Fieldset style="height: 198px;">

        <template #legend>
            <div><i class="pi" :class="`${icon} ${textColor}`"></i> {{ title }} </div>
        </template>

        <div class="text-center">
            <div class="text-center flex flex-column text-2xl font-bold">
                <span v-if="title == 'Total'" class="text-cyan-200 text-3xl"> {{ formatAmountToCurrency(amount) }} </span>
                <span v-else> {{ formatAmountToCurrency(amount) }} </span>
                <span class="mt-1"> {{ count }} Transações </span>
                <span class="text-orange-400 mt-1">
                    Taxas: {{ formatAmountToCurrency(totalTax)
                    }}
                </span>

                <span v-if="title !== 'Total'" class="mt-2" :class="textColor">
                    {{
                        formatAmountToCurrency(calculatedAmount)
                    }}
                </span>
            </div>
        </div>

    </Fieldset>
</template>

<script>

import Fieldset from 'primevue/fieldset'

export default {
    components: {
        Fieldset
    },
    props: {
        title: {
            type: String
        },
        icon: {
            type: String
        },
        textColor: {
            type: String
        },
        amount: {
            type: Number
        },
        count: {
            type: Number
        },
        totalTax: {
            type: Number
        },
        calculatedAmount: {
            type: Number
        }
    },
    setup() {

        function formatAmountToCurrency(amount) {
            if (!amount) amount = 0
            return parseFloat(amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }



        return {
            formatAmountToCurrency,
        }

    }
}
</script>