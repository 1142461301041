<template>
    <div class="layout-dashboard">

        <div class="grid">
            <div class="col-12">
                <div class="notification">
                    <h6>👋 Bem vindo ao Hub de Pagamentos.</h6>
                </div>
            </div>
        </div>

        <Summary />



    </div>
</template>

<script>

import Summary from './Summary.vue'

export default {
    components: {
        Summary
    },
}
</script>


